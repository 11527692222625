#root {
    position: relative;
    min-height: 100%;
}

#layout-wrapper {
    position: relative;
    height: 100%;
}

.main-content {
    height: 100%;
}


.logo-md {
    height: 4.5rem;
    width: 9.5rem;
}

.profile {
    display: flex;
    gap: 5px;

    &-image {
        flex-basis: 50px;

        & img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &-info {
        flex-direction: column;
        justify-self: center;
        display: flex;
        // align-items: center;
    }
}

.center-flex-y {
    display: flex;
    gap: 5px;
    align-items: center;
}

.left-right-item {
    display: flex;
    justify-content: space-between;

    :nth-child(2) {
        padding-left: 70px;
        text-align: right;
    }
}


.card-full-y {
    height: calc(100% - 24px);
}

.ant-tag {
    margin-inline-end: 0px;
}